import React, {useRef, useState, useContext, useEffect} from 'react';
import { AppContext, RadioProps } from '../../contexts/appContext';
import api from '../../api/api';
import PopularItem from './popularItem';
import './popular.css';

interface WidgetProps {
    title: string
}

const Popular: React.FC<WidgetProps> = ({title}) => {
    const {favouriteRadios} = useContext(AppContext);
    const [items, setItems] = useState<RadioProps[]>([]);

    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const isLongPress = useRef<boolean>(false);

    useEffect(() => {
        api({
          url: `radios/popular`,
          method: "GET"
        }).then ((response) => {
          if(response.status === 200 && response.data) {
            setItems(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, [])

    useEffect(() => {
        setItems(items);
    }, [favouriteRadios, items]);

    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const leftArrowRef = useRef<HTMLDivElement>(null);
    const rightArrowRef = useRef<HTMLDivElement>(null);
    //const [touchStartX, setTouchStartX] = useState<number | null>(null);
    const [dragStartX, setDragStartX] = useState<number | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const scrollAmount = 300; // Adjust the scroll amount as needed


    const handleScrollLeft = () => {
        const container = containerRef.current;
        const content = contentRef.current;
        const currentTranslateX = getTranslateX(content);
        const maxScroll = content?.scrollWidth ? content.scrollWidth - container?.clientWidth! : 0;
        if (content) {
            const newTranslateX = Math.min(0, currentTranslateX + scrollAmount);
            content.style.transform = `translateX(${newTranslateX}px)`;
            toggleNavigationIcons(newTranslateX, maxScroll);
        }
      };
    
      const handleScrollRight = () => {
        const container = containerRef.current;
        const content = contentRef.current;
        const maxScroll = content?.scrollWidth ? content.scrollWidth - container?.clientWidth! : 0;
        const currentTranslateX = getTranslateX(content);
        if (content) {
            const newTranslateX = Math.max(-maxScroll, currentTranslateX - scrollAmount);
            content.style.transform = `translateX(${newTranslateX}px)`;
            toggleNavigationIcons(newTranslateX, maxScroll);
        }
      };
    
      const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        //event.preventDefault();
        setDragStartX(event.touches[0].clientX);
        setIsDragging(true);
        contentRef.current?.classList.add('dragging');

        isLongPress.current = false;
        timerRef.current = setTimeout(() => {
          isLongPress.current = true;
        }, 100);
      };

      const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        if (!isDragging) {
          return;
        }
        
        const dragCurrentX = event.touches[0].clientX;
        const container = containerRef.current;
        const content = contentRef.current;
        const currentTranslateX = getTranslateX(content);

        if(!isLongPress.current) {
          if (dragStartX && container && content) {
            const dragDeltaX = (dragCurrentX - dragStartX) * 3;
            const maxScroll = content.scrollWidth  - container.clientWidth;
            const newTranslateX = Math.min(Math.max(currentTranslateX + dragDeltaX, -maxScroll), 0);
            content.style.transform = `translateX(${newTranslateX}px)`;
            toggleNavigationIcons(newTranslateX, maxScroll);
          }
        }

      };

      const handleTouchEnd = () => {
        setDragStartX(null);
        setIsDragging(false);
        contentRef.current?.classList.remove('dragging');
        
        if (timerRef.current) {
          clearTimeout(timerRef.current as NodeJS.Timeout | number);
          timerRef.current = null;
        }
      };


      const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragStartX(event.clientX);
        setIsDragging(true);
        contentRef.current?.classList.add('dragging');

        isLongPress.current = false;
        timerRef.current = setTimeout(() => {
          isLongPress.current = true;
        }, 100);
      };

      const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging) {
            return;
        }

        const dragCurrentX = event.clientX;
        const container = containerRef.current;
        const content = contentRef.current;
        const currentTranslateX = getTranslateX(content);
        
        if (Math.abs(dragCurrentX - (dragStartX as number)) < 30) {
            return;
        }
          
        if(!isLongPress.current) {
          if (dragStartX && container && content) {
            const dragDeltaX = (dragCurrentX - dragStartX) * 3;
            const maxScroll = content.scrollWidth  - container.clientWidth;
            //console.log("Content-ScrollWidth:" + content.scrollWidth);
            //console.log("Content-ClientWidth:" + content.clientWidth);
            //console.log("Container-ScrollWidth:" + container.scrollWidth);
            //console.log("Container-ClientWidth:" + container.clientWidth);
            const newTranslateX = Math.min(Math.max(currentTranslateX + dragDeltaX, -maxScroll), 0);
            //console.log("CurrentTranslateX:" + currentTranslateX);
            //console.log("DragDeltaX:" + dragDeltaX);
            //console.log("MaxScroll:" + maxScroll);
            //console.log("NewTranslateX:" + newTranslateX);
            //console.log("===================================");
            content.style.transform = `translateX(${newTranslateX}px)`;

            toggleNavigationIcons(newTranslateX, maxScroll);

          }
        }
      };
    
      const handleMouseUp = () => {
        setDragStartX(null);
        setIsDragging(false);
        contentRef.current?.classList.remove('dragging');
        //document.removeEventListener('mousemove', handleMouseMove);
        //document.removeEventListener('mouseup', handleMouseUp);

        if (timerRef.current) {
          clearTimeout(timerRef.current as NodeJS.Timeout | number);
          timerRef.current = null;
        }
      };

      const handleMouseLeave = () => {
        setDragStartX(null);
        setIsDragging(false);
        contentRef.current?.classList.remove('dragging');

        if (timerRef.current) {
          clearTimeout(timerRef.current as NodeJS.Timeout | number);
          timerRef.current = null;
        }
      }

      const getTranslateX = (element: HTMLElement | null) => {
        if (element) {
          const transformStyle = window.getComputedStyle(element).getPropertyValue('transform');
          const matrix = new DOMMatrixReadOnly(transformStyle);
          return matrix.m41;
        }
        return 0;
      };

      const toggleNavigationIcons = (translateX: number, maxScroll: number) => {
        if (translateX === 0){
            leftArrowRef.current?.classList.add("disabled");
          }

          if (translateX !== 0){
            leftArrowRef.current?.classList.remove("disabled");
          }

          if (translateX === -maxScroll){
            rightArrowRef.current?.classList.add("disabled");
          }

          if (translateX !== -maxScroll){
            rightArrowRef.current?.classList.remove("disabled");
          }
      };

    return (
        <div className='popular-widget' 
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
        >
            <div className='widget-header'>
                <div className='widget-title'>{title}</div>
                <div className='navigation-icons'>
                    <div ref={leftArrowRef} className="scrollable-arrows left disabled" onClick={() => handleScrollLeft()}>&lt;</div>
                    <div ref={rightArrowRef} className="scrollable-arrows right" onClick={() => handleScrollRight()}>&gt;</div>
                </div>
            </div>
            <div className='scrollable-component' ref={containerRef}>
                <div className='scrollable-content' ref={contentRef}>
                    {items.map((radio, index) => (
                        <PopularItem key = {index} radio = {radio}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Popular;