import React, {useEffect, useState, useContext} from 'react';
import { AppContext } from '../../contexts/appContext';
import {NavLink} from 'react-router-dom';
import './navigation.css';

interface NavigationProps {
    isMobileMenuOpen?: boolean,
}

const Navigation: React.FC<NavigationProps> = ({isMobileMenuOpen = false}) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(isMobileMenuOpen);
    const {favouriteRadios, recentlyPlayedRadios} = useContext(AppContext);

    const hasFavouriteRadios = favouriteRadios?.length > 0;
    const hasRecentlyPlayedRadios = recentlyPlayedRadios?.length > 0;

    useEffect(() => {
        setIsMenuOpen(isMobileMenuOpen);
    }, [isMobileMenuOpen])

    return (
        <div className={`navigation-bar ${isMenuOpen ? 'active' : ''}`}>
            <div className='nav'>
                <div className='nav-section'>
                    <div className='nav-section-title'>Ανακάλυψε</div>
                    <div className='nav-section-items'>
                        <div className='nav-item'>
                            <NavLink to = "/" aria-label='Αρχική' title='Αρχική'>
                                <span className='nav-icon'>
                                    <svg className='home-icon' fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path d="m17.79 22.75h-11.58c-2.74 0-4.96-2.23-4.96-4.97v-7.41c0-1.36003.84-3.07003 1.92-3.91003l5.39-4.2c1.62-1.259996 4.21-1.319996 5.89-.14l6.18 4.33c1.19.83 2.12 2.61 2.12 4.06003v7.28c0 2.73-2.22 4.96-4.96 4.96zm-8.31-19.31003-5.39 4.2c-.71.56-1.34 1.83-1.34 2.73003v7.41c0 1.91 1.55 3.47 3.46 3.47h11.58c1.91 0 3.46-1.55 3.46-3.46v-7.28c0-.96003-.69-2.29003-1.48-2.83003l-6.18-4.33c-1.14-.8-3.02-.76-4.11.09z"/>
                                            <path d="m12 18.75c-.41 0-.75-.34-.75-.75v-3c0-.41.34-.75.75-.75s.75.34.75.75v3c0 .41-.34.75-.75.75z"/>
                                        </g>
                                    </svg>
                                </span>
                                <span className='nav-label'>Αρχική</span>   
                            </NavLink>
                        </div>
                        <div className='nav-item'>
                            <NavLink to = "/categories" aria-label='Ανά Κατηγορία' title='Ανά Κατηγορία'>
                                <span className='nav-icon'>
                                    <svg className='categories-icon' fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path className='c1' d="m7.24 2h-1.9c-2.19 0-3.34 1.15-3.34 3.33v1.9c0 2.18 1.15 3.33 3.33 3.33h1.9c2.18 0 3.33-1.15 3.33-3.33v-1.9c.01-2.18-1.14-3.33-3.32-3.33z"/>
                                            <path className='c2' d="m18.6695 2h-1.9c-2.18 0-3.33 1.15-3.33 3.33v1.9c0 2.18 1.15 3.33 3.33 3.33h1.9c2.18 0 3.33-1.15 3.33-3.33v-1.9c0-2.18-1.15-3.33-3.33-3.33z"/>
                                            <path className='c1' d="m18.6695 13.4297h-1.9c-2.18 0-3.33 1.15-3.33 3.33v1.9c0 2.18 1.15 3.33 3.33 3.33h1.9c2.18 0 3.33-1.15 3.33-3.33v-1.9c0-2.18-1.15-3.33-3.33-3.33z"/>
                                            <path className='c2' d="m7.24 13.4297h-1.9c-2.19 0-3.34 1.15-3.34 3.33v1.9c0 2.19 1.15 3.34 3.33 3.34h1.9c2.18 0 3.33-1.15 3.33-3.33v-1.9c.01-2.19-1.14-3.34-3.32-3.34z"/>
                                        </g>
                                    </svg>
                                </span>
                                <span className='nav-label'>Ανά Κατηγορία</span>  
                            </NavLink>
                        </div>
                        <div className='nav-item'>
                            <NavLink to = "/location" aria-label='Ανά Περιοχή' title='Ανά Περιοχή'>
                                <span className='nav-icon'>
                                    <svg className='location-icon' fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path d="m15.6491 21.4102c-.43 0-.86-.09-1.21-.26l-5.25004-2.63c-.3-.15-.89-.14-1.18.03l-2.36 1.35c-1.02.58-2.07.66-2.86.19-.8-.46-1.25-1.4-1.25-2.58v-9.72004c0-.91.6-1.94 1.39-2.39l4.33-2.48c.73-.42 1.84-.45 2.59-.07l5.25004 2.63c.3.15.88.13 1.18-.03l2.35-1.34c1.02-.58 2.07-.66 2.86-.19.8.46 1.25 1.4 1.25 2.58v9.73004c0 .91-.6 1.94-1.39 2.39l-4.33 2.48c-.38.2-.88.31-1.37.31zm-7.01004-4.49c.43 0 .86.09 1.21.26l5.25004 2.63c.3.15.88.13 1.18-.03l4.33-2.48c.32-.18.63-.72.63-1.08v-9.73004c0-.63-.18-1.1-.51-1.28-.32-.18-.82-.11-1.36.2l-2.35 1.34c-.73.42-1.84.45-2.59.07l-5.25004-2.63c-.3-.15-.88-.13-1.18.03l-4.33 2.48c-.32.18-.63.72-.63 1.09v9.73004c0 .63.18 1.1.5 1.28.32.19.82.11 1.37-.2l2.35-1.34c.39-.23.89-.34 1.38-.34z"/>
                                            <path d="m8.56055 17.75c-.41 0-.75-.34-.75-.75v-13c0-.41.34-.75.75-.75s.75.34.75.75v13c0 .41-.34.75-.75.75z"/>
                                            <path d="m15.7305 20.7501c-.41 0-.75-.34-.75-.75v-13.37998c0-.41.34-.75.75-.75s.75.34.75.75v13.37998c0 .41-.34.75-.75.75z"/>
                                        </g>
                                    </svg>
                                </span>
                                <span className='nav-label'>Ανά Περιοχή</span>   
                            </NavLink>
                        </div>
                        <div className='nav-item'>
                            <NavLink to = "/popular" aria-label='Δημοφιλή' title='Δημοφιλή'>
                                <span className='nav-icon'>
                                    <svg className='trending-icon' fill="red" viewBox="0 0 256 256" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                        <g> 
                                            <path d="M244.00244,56.00513V120a12,12,0,0,1-24,0V84.9707l-75.51465,75.51465a11.99973,11.99973,0,0,1-16.9707,0L96.00244,128.9707,32.48779,192.48535a12.0001,12.0001,0,0,1-16.9707-16.9707l72-72a11.99973,11.99973,0,0,1,16.9707,0l31.51465,31.51465L203.03174,68h-35.0293a12,12,0,0,1,0-24h63.99512c.39746-.00024.79541.02075,1.1914.06006.167.01636.32911.04785.49366.071.22314.0315.44629.05786.66748.10181.19238.03809.37793.09131.56689.13843.19092.04761.3833.09009.57276.14721.18505.05616.36377.126.54492.19068.18847.06714.37793.12939.56347.2063.16846.06982.33008.1521.49415.22949.19091.08936.3833.17432.57031.27441.15527.0835.30273.17847.4541.26856.18506.10986.37207.21484.55225.33545.16455.11035.31884.2334.478.35156.15479.11523.31348.22314.46387.34692.28467.23365.55664.4812.81787.73951.019.01879.04.03418.05908.05322s.03467.04.05371.05908c.2583.262.50635.53418.73975.81885.12012.146.22461.2998.33691.45019.12159.16309.24805.32251.36133.49195.11865.177.22168.36084.33008.54272.0918.1543.189.30518.27393.46387.09863.18408.18213.37329.2705.56128.07862.16723.16211.33179.2334.50317.07569.18311.13721.37036.20362.55664.06591.18311.13623.36377.19287.551.05713.18823.09912.37964.14648.56982.04736.18946.10059.37622.13916.56909.04346.22071.07031.44361.10156.666.02344.16553.05518.32788.07129.49536Q244.00171,55.40808,244.00244,56.00513Z"></path> 
                                        </g>
                                    </svg>
                                </span>
                                <span className='nav-label'>Δημοφιλή</span>   
                            </NavLink>
                        </div>
                    </div>
                </div>
                {(hasFavouriteRadios || hasRecentlyPlayedRadios) &&
                    <div className='nav-section'>
                        <div className='nav-section-title'>Βιβλιοθήκη</div>
                        <div className='nav-section-items'>
                            {hasRecentlyPlayedRadios &&
                               <div className='nav-item'>
                                   <NavLink to = "/recently-played" aria-label='Άκουσες Πρόσφατα' title='Άκουσες Πρόσφατα'>
                                       <span className='nav-icon'>
                                           <svg className='music-icon' fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                               <path d="m20.8894 5.1786v11.3c0 1.98-1.61 3.59-3.59 3.59-1.97 0-3.59-1.61-3.59-3.59 0-1.97 1.62-3.58 3.59-3.58.84 0 1.59.29 2.2.77v-5.95l-9.21 2.62v8.07c0 1.98-1.62003 3.59-3.59003 3.59-1.98 0-3.58999-1.61-3.58999-3.59 0-1.97 1.60999-3.58 3.58999-3.58.83001 0 1.58001.29 2.19001.76v-8.84c0-1.47.89-2.61 2.30002-2.99l5.78-1.58c1.17-.32 2.16-.21 2.86.33.71.53 1.06 1.43 1.06 2.67z"/>
                                           </svg>
                                       </span>
                                       <span className='nav-label'>Άκουσες Πρόσφατα</span>   
                                   </NavLink>
                               </div>
                            }
                            {hasFavouriteRadios &&
                                <div className='nav-item'>
                                    <NavLink to = "/favourites" aria-label='Αγαπημένα' title='Αγαπημένα'>
                                        <span className='nav-icon'>
                                            <svg className='heart-icon' fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m16.44 3.10156c-1.81 0-3.43.88-4.44 2.23-1.01-1.35-2.63-2.23-4.44-2.23-3.07 0-5.56 2.5-5.56 5.59 0 1.19.19 2.29004.52 3.31004 1.58 5 6.45 7.99 8.86 8.81.34.12.9.12 1.24 0 2.41-.82 7.28-3.81 8.86-8.81.33-1.02.52-2.12004.52-3.31004 0-3.09-2.49-5.59-5.56-5.59z"/>
                                            </svg>
                                        </span>
                                        <span className='nav-label'>Αγαπημένα</span>   
                                    </NavLink>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Navigation;