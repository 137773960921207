import React from 'react';

const MaintenancePage = () => {
  return (
    <div>
      <h1>We'll Be Back Soon!</h1>
      <p>Our website is currently undergoing scheduled maintenance. Please check back later.</p>
      <p>Thank you for your patience!</p>
    </div>
  );
};

export default MaintenancePage;