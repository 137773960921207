import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppProvider } from './contexts/appContext';
import MaintenancePage from './MaintenancePage';

const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;
const appElement = document.getElementById('app');
  
if (appElement) {
  const root = ReactDOM.createRoot(appElement);

  // Get the current UTC time
  const currentDateTime = new Date();

  // Extract the current hour in UTC
  const currentHour = currentDateTime.getUTCHours();

  console.log(currentHour);

  // Define the time period (09 PM to 10 PM UTC)
  const startHour = 21; // 09 PM
  const endHour = 22;   // 10 PM


  if (maintenanceMode === 'true' && (currentHour >= startHour && currentHour < endHour)){
    root.render(<MaintenancePage />);
  }
  else {
    root.render(
      <AppProvider>
      {/* // <React.StrictMode> */}
          <App />
      {/* // </React.StrictMode> */}
      </AppProvider>
    );
  }
}